const getBaseUrl = () => import.meta.env.VITE_API_BASE_URL;

export const getUrl = (type) => {
  const baseUrl = getBaseUrl();
  switch (type) {
    case 'signup':
      return `${baseUrl}/auth/register/`;
    case 'user-exists':
      return `${baseUrl}/auth/user-exists/`;
    case 'profile':
      return `${baseUrl}/auth/profile`;
    case 'delete-account':
      return `${baseUrl}/auth/delete-logged-in-user/`;
    case 'remove-logo':
      return `${baseUrl}/auth/remove-logo`;
    case 'login':
      return `${baseUrl}/auth/login/`;
    case 'login-with-token':
      return `${baseUrl}/auth/remember-token/verify/`;
    case 'current_plan':
      return `${baseUrl}/auth/current_plan/`;
    case 'logout':
      return `${baseUrl}/auth/logout/`;
    case 'client':
      return `${baseUrl}/auth/client/`;
    case 'clients':
      return `${baseUrl}/auth/clients/`;
    case 'historical-clients':
      return `${baseUrl}/historical/clients/`;
    case 'change-password':
      return `${baseUrl}/auth/change_password/`;
    case 'forgot-password':
      return `${baseUrl}/auth/request-forget-email/`;
    case 'reset-password':
      return `${baseUrl}/auth/password-forget-complete`;
    case 'scenario':
      return `${baseUrl}/scenario/scenario/`;
    case 'scenario-details':
      return `${baseUrl}/scenario/scenariodetails/`;
    case 'scenario-with-schedule_id':
      return `${baseUrl}/scenario/scenario-schedule/`;
    case 'scenario-detail-templates':
      return `${baseUrl}/scenario/template-sceneriodetails/`;
    case 'scenario-schedule-templates':
      return `${baseUrl}/scenario/template-schedule/`;
    case 'simulations':
      return `${baseUrl}/historical/simulation/`;
    case 'simulation-details':
      return `${baseUrl}/historical/simulation-details/`;
    case 'simulation-detail-templates':
      return `${baseUrl}/historical/template-simulation-details/`;
    case 'historical-illustrations':
      return `${baseUrl}/historical/illustrations/`;
    case 'historical-illustration-template':
      return `${baseUrl}/historical/template-existing-illustration/`;
    case 'historical-parameters':
      return `${baseUrl}/historical/parameters/`;
    case 'historical-parameters-portfolio':
      return `${baseUrl}/historical/standalone-parameters-portfolio/`;
    case 'historical-template-insurance-profile':
      return `${baseUrl}/historical/standalone-template-insurance-profile/`;
    case 'historical-parameters-index-template':
      return `${baseUrl}/historical/parameters-template-index/`;
    case 'historical-parameters-portfolio':
      return `${baseUrl}/historical/standalone-parameters-portfolio/`;
    case 'simulation-report':
      return `${baseUrl}/report/historical-report/`
    case 'simulation-report-delete':
      return `${baseUrl}/report/historical-delete/`
    case 'add-historical-report':
      return `${baseUrl}/report/historical-report/`
    case 'share-historical-report':
      return `${baseUrl}/report/historical-share/`
    case 'standalone-report':
      return `${baseUrl}/historical/standalone-report/`
    case 'latest-report':
      return `${baseUrl}/report/latest-report/`
    case 'historical-latest-report':
      return `${baseUrl}/report/historical-latest-report/`
    case 'schedule':
      return `${baseUrl}/scenario/schedule/`;
    case 'illustration':
      return `${baseUrl}/scenario/illustration/`;
    case 'template-insurance-profile':
      return `${baseUrl}/scenario/template-insurance-profile/`;
      case 'combined-insurance-profile-template':
        return `${baseUrl}/historical/combined-data/`;
    case 'illustration-id':
      return `${baseUrl}/scenario/illustrationid/`;
    case 'illustration-template':
      return `${baseUrl}/scenario/template-exsiting-illustration/`;
    case 'pdf_extract':
      return `${baseUrl}/pdf/extract/`;
    case 'illustration-files':
      return `${baseUrl}/scenario/media/`;
    case 'vehicle-type1':
      return `${baseUrl}/scenario/vehicle-type1/`;
    case 'vehicle-type2':
      return `${baseUrl}/scenario/vehicle-type2/`;
    case 'vehicle-type3':
      return `${baseUrl}/scenario/vehicle-type3/`;
    case 'template-vehicle1':
      return `${baseUrl}/scenario/template-vehicle1/`;
    case 'template-vehicle2':
      return `${baseUrl}/scenario/template-vehicle2/`;
    case 'template-vehicle3':
      return `${baseUrl}/scenario/template-vehicle3/`;
    case 'vehicles-templates':
      return `${baseUrl}/scenario/vehicle-template/`;
    case 'vehicles-templates':
      return `${baseUrl}/scenario/vehicle-template/`;
    case 'vehicle-portfolio':
      return `${baseUrl}/scenario/vehicle-portfolio/`;
    case 'comparative':
      return `${baseUrl}/scenario/comparative/`
    case 'existing-comparative':
      return `${baseUrl}/scenario/exisiting-comparative/`
    case 'historical':
      return `${baseUrl}/scenario/historical/`;
    case 'historical-template':
      return `${baseUrl}/scenario/template-index/`;
    case 'historical-portfolio':
      return `${baseUrl}/scenario/historical-portfolio/`;
    case 'historical-simulation-object':
      return `${baseUrl}/scenario/historical-media/`
    case 'strategy-index1':
      return `${baseUrl}/scenario/indexstrategy1/`;
    case 'strategy-index2':
      return `${baseUrl}/scenario/indexstrategy2/`;
    case 'strategy-index3':
      return `${baseUrl}/scenario/indexstrategy3/`;
    case 'strategy-index-template1':
      return `${baseUrl}/scenario/template-indexstrategy1/`;
    case 'strategy-index-template2':
      return `${baseUrl}/scenario/template-indexstrategy2/`;
    case 'strategy-index-template3':
      return `${baseUrl}/scenario/template-indexstrategy3/`;
    case 'report':
      return `${baseUrl}/report/`
    case 'add-report':
      return `${baseUrl}/report/`
    case 'latest-report':
      return `${baseUrl}/report/latest-report/`
    case 'comparative_report':
      return `${baseUrl}/report/calc/`
    case 'comparative_report_dist':
      return `${baseUrl}/report/dist-report/`
    case 'comparative_report_ror':
      return `${baseUrl}/report/ror-report/`
    case 'comparative_report_longevity':
      return `${baseUrl}/report/longegivity/`
    case 'comparative_report_ending_value':
      return `${baseUrl}/report/surrender/`
    case 'comparative_report_death_benefit':
      return `${baseUrl}/report/death/`
    case 'comparative_report_longevity_ror':
      return `${baseUrl}/report/longevity-ror/`
    case 'comparative_report_ending_value_ror':
      return `${baseUrl}/report/surrender-ror/`
    case 'comparative_report_death_benefit_ror':
      return `${baseUrl}/report/death-ror/`
    case 'notes':
      return `${baseUrl}/report/add-note/`
    case 'historical-notes':
      return `${baseUrl}/report/historical-add-note/`
    case 'historical-disclosures':
      return `${baseUrl}/report/historical-add-disclosure/`
    case 'disclosures':
      return `${baseUrl}/report/add-disclosure/`
    case 'historical_report':
      return `${baseUrl}/historical/`
    case 'isc_calculate':
      return `${baseUrl}/isc/calculate/`
    case 'tax_scorecard':
      return `${baseUrl}/scorecard/`
    case 'retirement-buffer':
      return `${baseUrl}/retirement_buffer/`
    case 'incomeRider':
      return `${baseUrl}/retirement_buffer/income_rider`;
    case 'incomeRiderSimulation':
      return `${baseUrl}/retirement_buffer/income_rider_simulation`;
    default:
      return baseUrl;
  }
};


