<template lang="">
    <div v-for="(item, index) in reportList" :key="index">
        <div v-if="Number(index) < reportListLimit"  class="list-item">
            <div class="list-item-inner" @click="goToReport(`/report-builder/${item.id}`)">
                <div class="list-item-detail">
                <p class="semi-bold-fw fs-18 mb-0 clientNamePara"><router-link :to="`/report-builder/${item.id}`">{{item.name}}</router-link></p>
                <label class="medium-fw">{{item.description}}</label>
                <br>
                <span class="report-scenario-span mt-1" >{{item.scenario_name}}</span>
                </div>
                <div class="list-item-actions" @click="e => e.stopPropagation()">
                <label class="date-label grey-clr fs-14 semi-bold-fw">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"  xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8172 1.59583H2.33885C1.29631 1.59583 0.451172 2.44097 0.451172 3.4835V12.1384C0.451172 13.1809 1.29631 14.026 2.33885 14.026H10.9937C12.0362 14.026 12.8814 13.1809 12.8814 12.1384V4.69293L10.8814 6.69291V12.026H2.45117V3.59583H8.81725L10.8172 1.59583Z"  fill="#9D9D9D"/>
                    <path d="M5.51465 9.51606L6.66809 6.70245L8.3313 8.30895L5.51465 9.51606Z"  fill="#9D9D9D" />
                    <path d="M12.7425 0.604405C12.7865 0.560484 12.8575 0.559852 12.9022 0.602984L14.4181 2.06566C14.4639 2.10987 14.4646 2.18305 14.4196 2.22811L8.37761 8.28205C8.33363 8.32611 8.26244 8.32672 8.21773 8.28341L6.69811 6.8118C6.6524 6.76754 6.65182 6.69441 6.69682 6.64942L12.7425 0.604405Z" fill="#9D9D9D"/>
                    </svg>&nbsp;
                    {{$dateFormat(item.updated_at)}}
                </label>
                <div class="round-btns">
                    <button class="btn round-btn"  data-bs-toggle="modal" data-bs-target="#ReportBuilderNameChangeModal" @click="$emit('setReportActionId', item.id)" >
                    <span>Edit</span>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M10.8172 1.59583H2.33885C1.29631 1.59583 0.451172 2.44097 0.451172 3.4835V12.1384C0.451172 13.1809 1.29631 14.026 2.33885 14.026H10.9937C12.0362 14.026 12.8814 13.1809 12.8814 12.1384V4.69293L10.8814 6.69291V12.026H2.45117V3.59583H8.81725L10.8172 1.59583Z"  fill="#9D9D9D" />
                        <path d="M5.51465 9.51606L6.66809 6.70245L8.3313 8.30895L5.51465 9.51606Z"  fill="#9D9D9D" />
                        <path  d="M12.7425 0.604405C12.7865 0.560484 12.8575 0.559852 12.9022 0.602984L14.4181 2.06566C14.4639 2.10987 14.4646 2.18305 14.4196 2.22811L8.37761 8.28205C8.33363 8.32611 8.26244 8.32672 8.21773 8.28341L6.69811 6.8118C6.6524 6.76754 6.65182 6.69441 6.69682 6.64942L12.7425 0.604405Z" fill="#9D9D9D" />
                    </svg>
                    </button>
                    <button class="btn round-btn" data-bs-target="#reportShareModal" data-bs-toggle="modal" @click="shareReport(item)">
                    <span>Share</span>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path  d="M13.2957 5.28354C13.5982 4.98107 13.5982 4.49066 13.2957 4.18818C12.9933 3.88571 12.5028 3.88571 12.2004 4.18818L8.91401 7.47454C8.61153 7.77702 8.61153 8.26743 8.91401 8.5699C9.21648 8.87238 9.70689 8.87238 10.0094 8.5699L13.2957 5.28354Z"  fill="#9D9D9D" />
                        <path d="M12.1997 5.0934C12.5022 5.39587 12.9926 5.39587 13.2951 5.0934C13.5976 4.79092 13.5976 4.30051 13.2951 3.99804L10.0132 0.716189C9.71077 0.413714 9.22036 0.413714 8.91789 0.716189C8.61541 1.01866 8.61541 1.50907 8.91789 1.81155L12.1997 5.0934Z" fill="#9D9D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M7.20551 3.97937H11.4655C11.8932 3.97937 12.24 4.32614 12.24 4.75391C12.24 5.18167 11.8932 5.52844 11.4655 5.52844H7.20652L7.20551 4.75391C7.2056 5.52844 7.20628 5.52844 7.20652 5.52844L7.19716 5.52853L7.15489 5.52937C7.11593 5.53037 7.05607 5.53243 6.9783 5.53669C6.82253 5.54522 6.59626 5.56249 6.32331 5.59734C5.77338 5.66754 5.05393 5.80661 4.34549 6.07775C3.63432 6.34994 2.97401 6.73998 2.49606 7.28866C2.03145 7.82202 1.7063 8.54126 1.7063 9.55555V13.0569C1.7063 13.4847 1.35953 13.8314 0.931763 13.8314C0.503998 13.8314 0.157227 13.4847 0.157227 13.0569V9.55555C0.157227 8.16939 0.616292 7.0882 1.32801 6.27117C2.02639 5.46945 2.93451 4.95913 3.79178 4.63103C4.65178 4.30187 5.50076 4.1407 6.12715 4.06074C6.44237 4.0205 6.70624 4.0002 6.89357 3.98994C6.98734 3.9848 7.06226 3.98216 7.11521 3.9808C7.14169 3.98013 7.1627 3.97977 7.17786 3.97958L7.19616 3.9794L7.20194 3.97938L7.20397 3.97937L7.20551 3.97937C7.20584 3.97937 7.20541 3.97937 7.20551 4.75391V3.97937Z"  fill="#9D9D9D" />
                    </svg>
                    </button>
                    <button class="btn round-btn" @click="goToReport(`/report-builder/${item.id}?present=true`)">
                    <span>Present</span>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M2.75 4.02161V8.76314H11.25V4.02161H2.75ZM2.5 2.77161C1.94772 2.77161 1.5 3.21932 1.5 3.77161V9.01314C1.5 9.56542 1.94771 10.0131 2.5 10.0131H11.5C12.0523 10.0131 12.5 9.56542 12.5 9.01314V3.77161C12.5 3.21932 12.0523 2.77161 11.5 2.77161H2.5Z"  fill="#9D9D9D" />
                        <path  d="M6.37109 9.63812C6.37109 9.29294 6.65092 9.01312 6.99609 9.01312V9.01312C7.34127 9.01312 7.62109 9.29294 7.62109 9.63812V12.3881C7.62109 12.7333 7.34127 13.0131 6.99609 13.0131V13.0131C6.65092 13.0131 6.37109 12.7333 6.37109 12.3881V9.63812Z"  fill="#0E6651" />
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M7.62109 9.63812C7.62109 9.29294 7.34127 9.01312 6.99609 9.01312C6.65092 9.01312 6.37109 9.29294 6.37109 9.63812V12.3881C6.37109 12.7333 6.65092 13.0131 6.99609 13.0131C7.34127 13.0131 7.62109 12.7333 7.62109 12.3881V9.63812Z" fill="#9D9D9D" />
                        <path  d="M10.9565 12.161C11.2006 12.4051 11.2006 12.8008 10.9565 13.0449V13.0449C10.7124 13.289 10.3167 13.289 10.0726 13.0449L7.42096 10.3933C7.17688 10.1492 7.17688 9.75345 7.42096 9.50937V9.50937C7.66504 9.2653 8.06077 9.2653 8.30485 9.50937L10.9565 12.161Z" fill="#0E6651" />
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M10.0726 13.0449C10.3167 13.289 10.7124 13.289 10.9565 13.0449C11.2006 12.8008 11.2006 12.4051 10.9565 12.161L8.30485 9.50937C8.06077 9.2653 7.66504 9.2653 7.42096 9.50937C7.17688 9.75345 7.17688 10.1492 7.42096 10.3933L10.0726 13.0449Z"  fill="#9D9D9D" />
                        <path  d="M3.82475 13.0438C3.58068 13.2879 3.18495 13.2879 2.94087 13.0438V13.0438C2.69679 12.7998 2.69679 12.404 2.94087 12.16L5.59252 9.5083C5.8366 9.26423 6.23233 9.26423 6.4764 9.5083V9.5083C6.72048 9.75238 6.72048 10.1481 6.4764 10.3922L3.82475 13.0438Z"  fill="#0E6651" />
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M2.94087 12.16C2.69679 12.404 2.69679 12.7998 2.94087 13.0438C3.18495 13.2879 3.58068 13.2879 3.82475 13.0438L6.4764 10.3922C6.72048 10.1481 6.72048 9.75238 6.4764 9.5083C6.23233 9.26423 5.8366 9.26423 5.59252 9.5083L2.94087 12.16Z"  fill="#9D9D9D" />
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M1.25 1.76312V2.76312H12.75V1.76312H1.25ZM1 0.513123C0.447715 0.513123 0 0.960838 0 1.51312V3.01312C0 3.56541 0.447716 4.01312 1 4.01312H13C13.5523 4.01312 14 3.56541 14 3.01312V1.51312C14 0.960838 13.5523 0.513123 13 0.513123H1Z" fill="#9D9D9D" />
                    </svg>
                    </button>
                    <button class="btn round-btn">
                    <span>Download</span>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path  d="M0.250098 12.6761C0.250098 13.0998 0.593514 13.4432 1.01714 13.4432C1.44076 13.4432 1.78418 13.0998 1.78418 12.6761L1.78418 11.1421C1.78418 10.7184 1.44076 10.375 1.01714 10.375C0.593514 10.375 0.250098 10.7184 0.250098 11.1421L0.250098 12.6761Z" fill="#9D9D9D" />
                        <path  d="M6.367 8.8655C6.66655 9.16505 7.15221 9.16505 7.45176 8.8655C7.75131 8.56595 7.75131 8.08029 7.45176 7.78074L5.06529 5.39427C4.76574 5.09472 4.28008 5.09472 3.98053 5.39427C3.68098 5.69382 3.68098 6.17948 3.98053 6.47903L6.367 8.8655Z" fill="#9D9D9D" />
                        <path  d="M6.55528 7.77948C6.25573 8.07902 6.25573 8.56469 6.55528 8.86424C6.85482 9.16378 7.34049 9.16378 7.64004 8.86424L10.0265 6.47777C10.3261 6.17822 10.3261 5.69256 10.0265 5.39301C9.72696 5.09346 9.24129 5.09346 8.94175 5.39301L6.55528 7.77948Z" fill="#9D9D9D" />
                        <path  d="M12.2159 12.6761C12.2159 13.0998 12.5593 13.4432 12.983 13.4432C13.4066 13.4432 13.75 13.0998 13.75 12.6761V11.1421C13.75 10.7184 13.4066 10.375 12.983 10.375C12.5593 10.375 12.2159 10.7184 12.2159 11.1421V12.6761Z" fill="#9D9D9D" />
                        <path  d="M6.25205 8.38073C6.25205 8.80435 6.59547 9.14777 7.01909 9.14777C7.44272 9.14777 7.78613 8.80435 7.78613 8.38073L7.78613 1.01714C7.78613 0.593512 7.44272 0.250095 7.01909 0.250095C6.59547 0.250095 6.25205 0.593512 6.25205 1.01714L6.25205 8.38073Z"  fill="#9D9D9D" />
                        <path  d="M12.9829 12.2159H1.01704C0.593416 12.2159 0.25 12.5594 0.25 12.983C0.25 13.4066 0.593416 13.75 1.01704 13.75H12.9829C13.4065 13.75 13.7499 13.4066 13.7499 12.983C13.7499 12.5594 13.4065 12.2159 12.9829 12.2159Z" fill="#9D9D9D" />
                    </svg>
                    </button>
                    <button class="btn round-btn" data-bs-toggle="modal" data-bs-target="#deleteReportModal" @click="setActionId(item.id)">
                    <span>Delete</span>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"  d="M3.27159 12.4675H11.0086L12.0468 1.53235H2.17872L3.27159 12.4675ZM13.5127 1.50703C13.5855 0.739269 12.9818 0.0754395 12.2106 0.0754395H2.01414C1.24035 0.0754395 0.635718 0.74352 0.712665 1.51348L1.83531 12.7466C1.90214 13.4152 2.4648 13.9244 3.13679 13.9244H11.144C11.8185 13.9244 12.3823 13.4115 12.4462 12.7402L13.5127 1.50703Z" fill="#9D9D9D" />
                        <path  d="M9.37198 7.53595C9.82346 7.53595 10.1895 7.16996 10.1895 6.71848C10.1895 6.267 9.82346 5.901 9.37198 5.901H4.85182C4.40034 5.901 4.03434 6.267 4.03434 6.71848C4.03434 7.16996 4.40034 7.53595 4.85182 7.53595H9.37198Z" fill="#9D9D9D" />
                    </svg> 
                    </button>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center" v-if="reportList.length >= reportListLimit">
        <button v-if="!showAllList && reportList.length > reportListLimit" role="button" class="btn d-inline-block view-btn" @click="viewMore()">+ View More</button>
        <button v-if="showAllList" role="button" class="btn d-inline-block view-btn" @click="viewLess()">- View Less</button>
    </div>
</template>
<script>
import config from "../../../services/config.js";

export default {
  props: ["reports", "client"],
  emits : ["setReportActionId"],
  data() {
    return {
      showAllList: false,
      reportListLimit: config.REPORT_LIST_LIMIT,
    };
  },
  methods: {
    // expand the more reports data in list
    viewMore: function() {
      this.reportListLimit = this.reportList.length;
      this.showAllList = true;
    },
    // Show the less report data in list
    viewLess: function() {
      this.reportListLimit = config.REPORT_LIST_LIMIT;
      this.showAllList = false;
    },
    setActionId: function(id) {
      document.getElementById("deleteReportId").value = id;
    },
    // redirect to the report page
    goToReport: function(url) {
      return window.location.href = url;
    },
    // share report 
    shareReport: function(item){
      let client = this.$props.client;
      client = {firstname: client.firstname, lastname: client.lastname, middlename: client.middlename};
      this.$store.dispatch('shareReportData', {name: 'client', data: client});
      this.$store.dispatch('shareReportData', {name: 'report_id', data: item.id});
      this.$store.dispatch('shareReportData', {name: 'report_link', data: `${this.$appUrl()}/report/${item.id}/${item.view_token}`});
      this.$store.dispatch('shareReportData', {name: 'scenario', data: {name: item.scenario_name}});
    }
  },
  computed: {
    reportList() {
      // returns the report list with latest first order
      let reports = this.$props.reports;
      if (reports) {
        return this.$props.reports.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
      }
      return [];
    },
  },
};
</script>
<style lang="">
</style>